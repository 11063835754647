import "../scss/layout.scss";
import "./_clarity";
import { CdsIcon } from "@cds/core/icon";

class LayoutManager {

    readonly navTriggerElem = document.getElementById('layout-nav-trigger') as CdsIcon;
    readonly navElem = document.getElementById('layout-nav')!;

    constructor() {
        this.initNav();
    }

    initNav() {
        const container = document.createElement('div');
        const backdrop = document.createElement('div');
        const tabs = Array.from(this.navElem.children) as HTMLElement[];
        backdrop.addEventListener('click', () => {
            this.navElem.classList.remove('nav-expanded');
            this.setNavTrigger();
        });
        this.navTriggerElem.addEventListener('click', () => {
            this.navElem.classList.toggle('nav-expanded');
            this.setNavTrigger();
        });
        container.classList.add('nav-container');
        backdrop.classList.add('nav-backdrop');
        this.navElem.append(container, backdrop);
        container.append(...tabs);
        this.navElem.classList.add('nav-inited');
        this.navElem.setAttribute('style', '');
    }

    setNavTrigger() {
        this.navTriggerElem.shape = this.navElem.classList.contains('nav-expanded') ? 'window-close' : 'bars';
    }
}

new LayoutManager();
import '@cds/core/icon/register';
import '@cds/core/button/register';
import '@cds/core/select/register';
import '@cds/core/input/register';
import '@cds/core/checkbox/register';
import '@cds/core/password/register';
import '@cds/core/datalist/register';
import '@cds/core/alert/register';
import '@cds/core/modal/register';
import '@cds/core/forms/register';
import '@cds/core/navigation/register';
import '@cds/core/progress-circle/register';

import {
    arrowIcon,
    banIcon,
    barsIcon,
    checkCircleIcon,
    checkIcon,
    ClarityIcons,
    copyIcon,
    downloadIcon,
    dragHandleIcon,
    euroIcon,
    exclamationTriangleIcon,
    folderIcon,
    folderOpenIcon,
    imageIcon,
    plusCircleIcon,
    sadFaceIcon,
    shareIcon,
    timesIcon,
    trashIcon,
    uploadCloudIcon,
    userIcon,
    wandIcon,
    windowCloseIcon
} from '@cds/core/icon';

ClarityIcons.addIcons(
    downloadIcon,
    exclamationTriangleIcon,
    plusCircleIcon,
    userIcon,
    barsIcon,
    windowCloseIcon,
    checkIcon,
    timesIcon,
    wandIcon,
    sadFaceIcon,
    checkCircleIcon,
    trashIcon,
    imageIcon,
    folderIcon,
    folderOpenIcon,
    shareIcon,
    uploadCloudIcon,
    arrowIcon,
    banIcon,
    copyIcon,
    dragHandleIcon,
    euroIcon,
);